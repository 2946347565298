body { background-color: #ffffff; }
body { color: #000000; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-7991);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-7991);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-7991);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-7991);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-7991);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: #ffffff;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.2;
font-size: 30px;

@media #{$medium-up} {
font-size: 40px;

}
@media #{$large-up} {
font-size: 50px;

}
}
.button {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-7991);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 18;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 20px;

}
}
summary {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$large-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$large-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$large-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-7991);}
a:hover {color: var(--clr-7992);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-7991);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-7991);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-7991);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-7993);
&:hover {background-color: var(--clr-7991);}
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-7993);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-7993);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
a.MEC5 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-7994);
color: #000000;
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-7994);
color: #000000;
 }
.MES7 {
background-color: var(--clr-7994);
color: #000000;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: var(--clr-7991);
 }
 }
cite.MEC7{
color: #000000;
}
/* Header Panel:8 */
.MES8 {
color: var(--clr-7991);
font-size: 21px;
@media #{$medium-up} {
font-size: 27.2px;
};
@media #{$large-up} {
font-size: 16px;
};
border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent var(--clr-7994) transparent;
 }
.MES8 {
color: var(--clr-7991);
font-size: 21px;
@media #{$medium-up} {
font-size: 27.2px;
};
@media #{$large-up} {
font-size: 16px;
};
border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent var(--clr-7994) transparent;
 }
a.MEC8 { color: var(--clr-7991);
&:hover { color: var(--clr-7993);}
 }
cite.MEC8{
color: var(--clr-7991);
font-size: 21px;
@media #{$medium-up} {
font-size: 27.2px;
};
@media #{$large-up} {
font-size: 16px;
};
}
/* Main Menu:9 */
nav.me-Menu.MES9 {
.menu-item.MEC9{background-color: var(--clr-7991); &:hover {background-color: var(--clr-7994);}
}
& .menu-item.MEC9, & .menu-item.MEC9 > div.MEC9{ & > a.MEC9{color: #ffffff;
}
 &:hover > a.MEC9{color: var(--clr-7991);
}
 }
&.horizontal > .menu-item.MEC9 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC9 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC9 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 2;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC9 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 36px 20px;}

& .sub-menu .menu-item a{padding: 10px;}


& > .menu-item.MEC9.active { color: var(--clr-7994);& > a{ color: var(--clr-7991);}
 }
&.horizontal .menu-item.MEC9:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-7993-flat);}}
&.vertical .menu-item.MEC9:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-7993-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC9{background-color: var(--clr-7993); &:hover {background-color: 6;}
}
& .menu-item.MEC9, & .menu-item.MEC9 > div.MEC9{ & > a.MEC9{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC9{color: 2;
}
 }

}
}
 }
/* Category Block Panel:13 */
.MES13 {
background-color: var(--clr-7994);
 }
.MES13 {
background-color: var(--clr-7994);
 }
/* Home Slider Panel:10 */
.MES10 {
background-color: var(--clr-7993);
& > .underlay, & > .inner-overlay { opacity: 0.5 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://cevol.com.au/img/589/20');
background-position: center left;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:200px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES10 {
background-color: var(--clr-7993);
& > .underlay, & > .inner-overlay { opacity: 0.5 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://cevol.com.au/img/589/20');
background-position: center left;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:200px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 500px;};
 }
/* Home Slider:11 */
.MES11 {
& .slider-arrow {color: #ffffff;
font-size: 50px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: var(--clr-7994);
} }
/* Slider Text Panel:12 */
.MES12 {
background-color: var(--clr-7995);
color: #000000;
padding: 15px;

@media #{$medium-up} {
padding: 20px 40px;

}
 }
.MES12 {
background-color: var(--clr-7995);
color: #000000;
padding: 15px;

@media #{$medium-up} {
padding: 20px 40px;

}
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: var(--clr-7991);
 }
 }
cite.MEC12{
color: #000000;
}
/* Parallax:14 */
.MES14 {
background-color: var(--clr-7993);
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://cevol.com.au/img/590/25');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 170%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 50px 15px;

}
@media #{$large-up} {
padding: 100px 15px;

}
 }
.MES14 {
background-color: var(--clr-7993);
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://cevol.com.au/img/590/25');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 170%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 50px 15px;

}
@media #{$large-up} {
padding: 100px 15px;

}
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
 }
cite.MEC14{
color: #ffffff;
}
/* Footer Menu:15 */
nav.me-Menu.MES15 {
& .menu-item.MEC15, & .menu-item.MEC15 > div.MEC15{ & > a.MEC15{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC15{color: #dcdcdc;
}
 }
&.horizontal > .menu-item.MEC15 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC15 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC15 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC15 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Footer Light Panel:16 */
.MES16 {
background-color: var(--clr-7994);
color: #ffffff;
 }
.MES16 {
background-color: var(--clr-7994);
color: #ffffff;
h1.MEC16, h2.MEC16, h3.MEC16, h4.MEC16, h5.MEC16, h6.MEC16 { color: #ffffff;
 }
 }
a.MEC16 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC16{
color: #ffffff;
}
/* Hollow:17 */
.MES17 {
background-color: transparent;
&:hover {background-color: transparent;}
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px 40px;

border-width: 3px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* Blue Heading Panel:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: var(--clr-7991);
 }
 }
/* Product Divider:20 */
.MES20 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-7997) transparent transparent transparent;}
& > hr {border-top-style: dotted;}
 }
/* File:21 */
.MES21 {
background-color: var(--clr-7994);
&:hover {background-color: var(--clr-7998);}
color: var(--clr-7991);
&:hover { color: var(--clr-7991);}
padding: 5px 10px;

 }
/* Dark Hollow:22 */
.MES22 {
background-color: transparent;
&:hover {background-color: transparent;}
color: var(--clr-7991);
&:hover { color: var(--clr-7991);}
padding: 10px 40px;

border-width: 3px;
border-style: solid;
border-color: var(--clr-7991);
&:hover { border-color: var(--clr-7991); }
 }
/* Responsive Menu:23 */
nav.responsive-menu {
.menu-item.MEC23{background-color: var(--clr-7991);}
& .menu-item.MEC23, & .menu-item.MEC23 > div.menu-item-wrap{ & > a.MEC23, & > i{color: #ffffff;
text-transform: uppercase;
text-align: left;
}
  }
& .menu-item.MEC23 { border:0;
border-color: #ffffff;border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px;}

& .sub-menu{.menu-item.MEC23{background-color: 8;}
& .menu-item.MEC23, & .menu-item.MEC23 > div.menu-item-wrap{ & > a.MEC23, & > i{color: 2;
text-transform: uppercase;
text-align: left;
}
  }
 .sub-menu {}}

 }
/* Credits panel in footer:24 */
.MES24 {
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
.MES24 {
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
cite.MEC24{
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
/* Footer panel:25 */
.MES25 {
background-color: var(--clr-7991);
color: #ffffff;
 }
.MES25 {
background-color: var(--clr-7991);
color: #ffffff;
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: #ffffff;
 }
 }
a.MEC25 { color: var(--clr-7994);
&:hover { color: var(--clr-7993);}
 }
cite.MEC25{
color: #ffffff;
}
/* Main Menu White:26 */
nav.me-Menu.MES26 {
.menu-item.MEC26{background-color: #ffffff; &:hover {background-color: var(--clr-7994);}
}
& .menu-item.MEC26, & .menu-item.MEC26 > div.MEC26{ & > a.MEC26{color: var(--clr-7991);
}
 &:hover > a.MEC26{color: var(--clr-7991);
}
 }
&.horizontal > .menu-item.MEC26 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC26 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC26 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 24;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC26 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 36px 12px;}

& .sub-menu .menu-item a{padding: 10px 6px 10px 12px;}


& > .menu-item.MEC26.active { color: var(--clr-7994);& > a{ color: var(--clr-7991);}
 }
&.horizontal .menu-item.MEC26:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-7993-flat);}}
&.vertical .menu-item.MEC26:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-7993-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC26{background-color: var(--clr-7993); &:hover {background-color: 6;}
}
& .menu-item.MEC26, & .menu-item.MEC26 > div.MEC26{ & > a.MEC26{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC26{color: 2;
}
 }

}
}
 }
/* Shade 1:27 */
.MES27 {
background-color: var(--clr-7992);
&:hover {background-color: var(--clr-7998);}
 }
/* Shade 1:28 */
.MES28 {
background-color: var(--clr-7992);
 }
.MES28 {
background-color: var(--clr-7992);
 }
/* Phone number link header:29 */
.MES29 {
color: var(--clr-7991);
 }
.MES29 {
color: var(--clr-7991);
 }
a.MEC29 { color: var(--clr-7992);
&:hover { color: var(--clr-7993);}
 }
cite.MEC29{
color: var(--clr-7991);
}
/* Blue Button:30 */
.MES30 {
background-color: var(--clr-7991);
&:hover {background-color: var(--clr-7993);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 16.8px;
@media #{$medium-up} {
font-size: 20.8px;
};
@media #{$large-up} {
font-size: 22.4px;
};
padding: 15px;

 }
/* Orange Button:31 */
.MES31 {
background-color: var(--clr-7992);
&:hover {background-color: var(--clr-7998);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 16.8px;
@media #{$medium-up} {
font-size: 20.8px;
};
@media #{$large-up} {
font-size: 22.4px;
};
padding: 15px;

 }
/* Top Strip:32 */
.MES32 {
background-color: var(--clr-7991);
color: #ffffff;
 }
.MES32 {
background-color: var(--clr-7991);
color: #ffffff;
 }
a.MEC32 { color: #ffffff;
&:hover { color: var(--clr-7993);}
 }
cite.MEC32{
color: #ffffff;
}
/* Slider Text Panel 2:33 */
.MES33 {
background-color: transparent;
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 20px 40px;

}
 }
.MES33 {
background-color: transparent;
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 20px 40px;

}
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;
 }
 }
cite.MEC33{
color: #ffffff;
}
/* Mask slider:34 */
.MES34 {
background-color: var(--clr-7999);
 }
.MES34 {
background-color: var(--clr-7999);
 }
/* Aqua Button:35 */
.MES35 {
background-color: var(--clr-7993);
&:hover {background-color: var(--clr-7991);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 16.8px;
@media #{$medium-up} {
font-size: 20.8px;
};
padding: 15px;

 }
/* thumbnail padd:36 */
.MES36 {
& .slick-slider{padding: 2px;}

 }
/* thumbnail padd:37 */
.MES37 {
padding: 2px;

 }
.MES37 {
padding: 2px;

 }
/* Accordion:38 */
details.MES38 {
& > summary{background-color: var(--clr-8000);
}
 }
/* Light:39 */
.MES39 {
background-color: var(--clr-8000);
 }
/* Light:40 */
.MES40 {
background-color: var(--clr-8000);
 }
.MES40 {
background-color: var(--clr-8000);
 }
/* Accordian 2:41 */
details.MES41 {
& > summary{background-color: var(--clr-8000);
}
& > summary{padding: 10px;}

& > article{padding: 10px;}

@media #{$large-up} {
& > article{padding: 10px 40px;}

}
 }
/* Background Image:42 */
.MES42 {
background-color: var(--clr-7991);
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://cevol.com.au/img/17498/8370');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;} }
.MES42 {
background-color: var(--clr-7991);
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://cevol.com.au/img/17498/8370');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;} }
